import { z } from "zod";

const Container = z.object({
  Id: z.string(),
  Active: z.boolean().nullable(),
  FillLevel: z.string().nullable(),
  Fraction: z.string().nullable(),
  Open: z.boolean().nullable(),
  Operative: z.boolean().nullable(),
  Updated: z.string().nullable(),
});

const Unit = z.object({
  Id: z.string(),
  Name: z.string().nullable(),
  Owner: z.string().nullable(),
  Address: z.string().nullable(),
  Containers: z.array(Container),
});

const AppData = z.object({
  Units: z.array(Unit),
});

export { Container, Unit, AppData };
export type AppDataType = z.infer<typeof AppData>;
export type UnitType = z.infer<typeof Unit>;
export type ContainerType = z.infer<typeof Container>;

import React from "react";
import { StatusBar } from "expo-status-bar";
import { Amplify, I18n } from "aws-amplify";
import awsconfig from "./src/aws-exports";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react-native";
import MainView from "./components/MainView";
import { translations } from "@aws-amplify/ui";
import authTheme from "./styles/authTheme";
import authTranslations from "./translations/authTranslations";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PaperProvider } from "react-native-paper";
import { StrombergLightTheme } from "./styles/strombergTheme";
import { NavigationContainer } from "@react-navigation/native";

Amplify.configure(awsconfig);
I18n.putVocabularies(translations);
I18n.setLanguage("no");
authTranslations();
const queryClient = new QueryClient();

const App = () => {
  return (
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={authTheme}>
          <PaperProvider theme={StrombergLightTheme}>
            <Authenticator
              // will wrap every subcomponent
              Container={(props) => (
                // reuse default `Container` and apply custom background
                <Authenticator.Container {...props} />
              )}
              components={{
                SignIn: ({ fields, ...props }) => (
                  <Authenticator.SignIn {...props} fields={[...fields]} />
                ),
                SignUp: ({ fields, ...props }) => (
                  <Authenticator.SignUp
                    {...props}
                    fields={[
                      ...fields,
                      {
                        name: "address",
                        placeholder: "Adresse",
                        label: "Din fulle adresse",
                        type: "default",
                      },
                    ]}
                  />
                ),
              }}
            >
              <StatusBar style="dark" />
              <NavigationContainer theme={StrombergLightTheme}>
                <MainView />
              </NavigationContainer>
            </Authenticator>
          </PaperProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Authenticator.Provider>
  );
};

export default App;

import axios, { AxiosResponse } from "axios";

const getErrorMessageOnRegistration = (
  errorMessage: string | undefined
): string => {
  if (errorMessage === "The given external tag ID does not exist in DynamoDB") {
    return "Brikken med denne id'en ble ikke funnet. Venligst sjekk at id'en stemmer med brikken du har fått tilsendt";
  }
  if (errorMessage === "no household found for given address") {
    return "Finner ingen boenhet på denne addressen. Venligst spesifiser husnummer og/eller husbokstav og leilighetsnummer";
  }
  if (errorMessage?.includes("email address")) {
    return "Denne eposten er allerede i bruk. Logg inn på nytt for å få tilgang til appen";
  }
  if (errorMessage === "more than one household found for given address") {
    return "Det finnes flere boenheter på denne addressen, venligst spesifiser husbokstav og/eller leilighetsnummer";
  }
  return "Noe har gått galt. Venligst prøv igjen";
};

export default getErrorMessageOnRegistration;

export const colors = {
  // strombergGreen: "#006c40",
  lightGreen: "#acec9fd3",
  darkGreen: "#006C40",
  shinyGreen: "#52d196",
  bottomTabGreen: "#2BB900",
  paleWhite: "#FFFAEA",
  grey: "#B9B9B9",
  lightGrey: "#EDEDED",
  white: "#ffffff",
  errorRed: "#FF602E",
  errorOrange: "#FFE5BF",
};

export const layout = {
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
};

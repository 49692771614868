import React, { useState, useEffect } from "react";
import { View, StyleSheet, ScrollView, Image } from "react-native";
import SectionTitle from "../components/shared/SectionTitle";
import { getAppUsers, addAppUser } from "../queryFunctions/appUsers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { NAVIGATION_ROUTES } from "../types/navigation";
import {
  ActivityIndicator,
  Button,
  Card,
  Text,
  TextInput,
} from "react-native-paper";
import { StrombergLightTheme } from "../styles/strombergTheme";
import isFetchingError from "../utilities/isFetchingError";
import { ZodError } from "zod";
import isErrorObject from "../utilities/isErrorObject";
import getErrorMessageOnRegistration from "../utilities/getErrorMessageOnRegistration";

const SignUpScreen = ({ navigation }) => {
  const [tagId, setTagId] = useState("");
  const [streetName, setStreetName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [letter, setLetter] = useState("");
  const [aptNumber, setAptNumber] = useState("");

  const [tagErrorMessage, setTagErrorMessage] = useState("");
  const [streetNameErrorMessage, setStreetNameErrorMessage] = useState("");
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState("");
  const [streetNumberErrorMessage, setStreetNumberErrorMessage] = useState("");
  const [formError, setFormError] = useState("");

  const {
    data: appUserData,
    error: appUserError,
    isLoading: isLoadingAppUser,
  } = useQuery({
    queryKey: ["appUsers"],
    queryFn: getAppUsers,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: addUserMutation,
    isLoading: isAddUserLoading,
    error: addUserError,
  } = useMutation({
    mutationKey: ["appUsers"],
    mutationFn: addAppUser,
    onSuccess() {
      navigation.navigate(NAVIGATION_ROUTES.HOME);
    },
  });

  useEffect(() => {
    if (appUserData?.InternalTagId) {
      localStorage.setItem("tagId", appUserData.InternalTagId);
      navigation.navigate(NAVIGATION_ROUTES.HOME);
    }
  }, [appUserData]);

  useEffect(() => {
    if (addUserError) {
      if (isFetchingError(addUserError)) {
        console.log(addUserError.response);
        if (isErrorObject(addUserError.response?.data)) {
          setFormError(
            getErrorMessageOnRegistration(addUserError.response?.data.error)
          );
          return;
        }
        setFormError(
          getErrorMessageOnRegistration(addUserError.response?.data)
        );
        return;
      }
      if (addUserError instanceof ZodError) {
        console.log("Something went wrong validating the response");
      }
    }
  }, [addUserError]);

  // TODO: Implement for validationg through library
  const handleSubmit = () => {
    if (tagId === "") {
      setTagErrorMessage("Venligst fyll ut brikke id");
      setFormError("Venligst fyll ut alle de påkrevde feltene");
      return;
    }
    if (streetName === "") {
      setStreetNameErrorMessage("Venligst fyll ut gatenavn");
      setFormError("Venligst fyll ut alle de påkrevde feltene");
      return;
    }
    if (postalCode === "" || Number.isNaN(Number(postalCode))) {
      setPostalCodeErrorMessage("Venligst fyll ut et gyldig postnummer");
      setFormError("Venligst fyll ut alle de påkrevde feltene");
      return;
    }

    setFormError("");
    addUserMutation({
      TagId: tagId,
      StreetName: streetName,
      StreetNumber: Number.isNaN(streetNumber) ? null : Number(streetNumber),
      PostalCode: Number(postalCode),
      Apartment: aptNumber === "" ? null : aptNumber,
      Letter: letter === "" ? null : letter,
    });
  };

  const handleInput = (
    setValue: (val: string) => void,
    setError: (val: string) => void,
    value: string,
    error: string
  ) => {
    setValue(value);

    if (value === "") {
      setError(error);
      return;
    }
    setError("");
  };

  return (
    <ScrollView contentContainerStyle={styles.scrollview}>
      {isLoadingAppUser ? (
        <View>
          <ActivityIndicator size="large" />
        </View>
      ) : appUserError ? (
        <>
          <Card
            mode="elevated"
            elevation={4}
            style={styles.formCard}
            contentStyle={styles.formCardContent}
          >
            <SectionTitle
              title="Register en brikke for å komme i gang"
              size={"large"}
            />
            <Text variant="bodyLarge" style={styles.descriptionText}>
              Registrer din brikke og din addresse for å få tilgang til åpning
              av avfallscontainere via appen
            </Text>
            <View style={styles.formContainer}>
              <TextInput
                style={styles.inputField}
                label="Brikke id*"
                mode="outlined"
                placeholder="Koden på brikken din"
                value={tagId}
                dense
                error={tagErrorMessage !== ""}
                onChangeText={(tagId) => {
                  handleInput(
                    setTagId,
                    setTagErrorMessage,
                    tagId,
                    "Venligst fyll ut brikke id"
                  );
                }}
              />
              <Text style={styles.errorText}>{tagErrorMessage}</Text>
              <TextInput
                style={styles.inputField}
                label="Gatenavn* "
                mode="outlined"
                placeholder="Gatenavn"
                value={streetName}
                dense
                error={streetNameErrorMessage !== ""}
                onChangeText={(streetName) => {
                  handleInput(
                    setStreetName,
                    setStreetNameErrorMessage,
                    streetName,
                    "Venligst fyll ut gatenavn"
                  );
                }}
              />
              <Text style={styles.errorText}>{streetNameErrorMessage}</Text>
              <TextInput
                style={[styles.inputField]}
                label="Postnummer* "
                mode="outlined"
                inputMode="numeric"
                placeholder="Postnummer"
                value={postalCode}
                dense
                maxLength={4}
                error={postalCodeErrorMessage !== ""}
                onChangeText={(postalCode) => {
                  if (Number.isNaN(Number(postalCode))) {
                    setPostalCodeErrorMessage(
                      "Venligst velg et gyldig postnummer"
                    );
                    setPostalCode(postalCode);
                    return;
                  }
                  handleInput(
                    setPostalCode,
                    setPostalCodeErrorMessage,
                    postalCode,
                    "Venligst fyll ut postnummer"
                  );
                }}
              />
              <Text style={styles.errorText}>{postalCodeErrorMessage}</Text>
              <View style={styles.addressDetailsContainer}>
                <TextInput
                  style={[styles.leftHalfSizedInput, styles.inputField]}
                  label="Husnummer"
                  mode="outlined"
                  placeholder="Husnummer"
                  value={streetNumber}
                  dense
                  inputMode="numeric"
                  error={streetNumberErrorMessage !== ""}
                  onChangeText={(streetNumber) => {
                    if (Number.isNaN(Number(streetNumber))) {
                      setStreetNumberErrorMessage(
                        "Venligst velg et gyldig husnummer"
                      );
                      setStreetNumber(streetNumber);
                      return;
                    }
                    setStreetNumber(streetNumber);
                    setStreetNumberErrorMessage("");
                  }}
                />
                <TextInput
                  style={[styles.rightHalfSizedInput, styles.inputField]}
                  label="Husbokstav"
                  mode="outlined"
                  placeholder="Husbokstav"
                  value={letter}
                  dense
                  onChangeText={(letter) => setLetter(letter)}
                />
              </View>

              <Text style={styles.errorText}>{streetNumberErrorMessage}</Text>

              <TextInput
                style={[styles.inputField]}
                label="Leilighetsnummer"
                mode="outlined"
                placeholder="Leilighetsnummer"
                value={aptNumber}
                dense
                onChangeText={(aptNumber) => setAptNumber(aptNumber)}
              />
            </View>

            <Text style={[styles.errorText, styles.generalError]}>
              {formError}
            </Text>
            <Button
              style={styles.button}
              mode="contained"
              loading={isAddUserLoading}
              onPress={() => {
                handleSubmit();
              }}
            >
              Registrer brikke
            </Button>
          </Card>

          <View style={styles.imageContainer}>
            <Image
              style={styles.logo}
              source={require("../img/logo-hires.png")}
            />
          </View>
        </>
      ) : (
        <View>
          <SectionTitle
            title="Gå hjem for å få tilgang til åpning av containere"
            size={"large"}
          />
          <Button
            mode="contained"
            onPress={() => navigation.navigate(NAVIGATION_ROUTES.HOME)}
          >
            Gå hjem
          </Button>
        </View>
      )}
    </ScrollView>
    // </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  screenWrapper: {
    flex: 1,
  },
  scrollview: {
    flex: 1,
    margin: 16,
    justifyContent: "center",
  },
  descriptionText: {
    textAlign: "center",
    marginVertical: 8,
  },
  formContainer: {
    justifyContent: "center",
    marginVertical: 16,
  },
  formCard: {
    padding: 10,
  },
  formCardContent: {
    justifyContent: "space-between",
  },
  imageContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginVertical: 10,
  },
  addressDetailsContainer: {
    flexDirection: "row",
  },
  inputField: {
    marginVertical: 3,
  },
  leftHalfSizedInput: {
    flex: 1,
    marginRight: 2,
  },
  rightHalfSizedInput: {
    flex: 1,
    marginLeft: 2,
  },
  logo: {
    marginVertical: 16,
    width: 90,
    height: 90,
    opacity: 0.4,
  },
  errorText: {
    color: StrombergLightTheme.colors.error,
  },
  generalError: {
    textAlign: "center",
  },
});

export default SignUpScreen;

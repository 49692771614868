import React, { useEffect, useState } from "react";
import { Button, List, Portal, Snackbar } from "react-native-paper";
import { ContainerType } from "../../validators/appData";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useMutation } from "@tanstack/react-query";
import { openContainer } from "../../queryFunctions/appData";
import { NAVIGATION_ROUTES } from "../../types/navigation";
import { StrombergLightTheme } from "../../styles/strombergTheme";

interface ContainerListItemProps {
  container: ContainerType;
  address: string | null;
  locationName: string | null;
  owner: string | null;
}

const ContainerListItem = ({
  container,
  address,
  locationName,
  owner,
}: ContainerListItemProps) => {
  const navigation = useNavigation();
  const [isOpen, setIsOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const {
    mutate: openContainerMutation,
    isLoading: isOpenContainerLoading,
    isSuccess: isOpenContainerSuccess,
    isError: isOpenContainerError,
  } = useMutation({
    mutationFn: openContainer,
  });

  useEffect(() => {
    if (isOpenContainerSuccess) {
      setIsOpen(true);
      setShowSnackbar(true);
    }

    setTimeout(function () {
      setIsOpen(false);
      setShowSnackbar(false);
    }, 7000);
  }, [isOpenContainerSuccess]);

  useEffect(() => {
    if (isOpenContainerError) {
      setShowErrorSnackbar(true);
    }

    setTimeout(function () {
      setShowErrorSnackbar(false);
    }, 7000);
  }, [isOpenContainerError]);

  return (
    <>
      <List.Item
        title={container.Fraction ?? "Ukjent"}
        description={address ?? "Ukjent adresse"}
        right={() => (
          <Button
            icon={
              isOpen
                ? "lock-open-check"
                : isOpenContainerError
                ? "lock-open-remove"
                : "lock-open"
            }
            disabled={!container.Active}
            mode="elevated"
            onPress={() => openContainerMutation(container.Id)}
            loading={isOpenContainerLoading}
          >
            {isOpen ? "Åpen" : container.Active ? "Åpne" : "Inaktiv"}
          </Button>
        )}
        style={styles.listItem}
        onPress={() =>
          navigation.navigate(NAVIGATION_ROUTES.CONTAINER, {
            container,
            address,
            locationName,
            owner,
          })
        }
      />
      <Portal>
        <Snackbar
          style={styles.snackSuccess}
          visible={showSnackbar}
          onDismiss={() => setShowSnackbar(false)}
          action={{
            label: "Lukk",
          }}
        >
          Container åpnet
        </Snackbar>
      </Portal>

      <Portal>
        <Snackbar
          visible={showErrorSnackbar}
          onDismiss={() => setShowErrorSnackbar(false)}
          action={{
            label: "Lukk",
          }}
        >
          Containeren kunne ikke åpnes
        </Snackbar>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  listItem: {
    borderBottomWidth: 1,
    borderRadius: 5,
    marginVertical: 2,
    borderColor: `lightgrey`,
  },
  snackSuccess: {
    backgroundColor: StrombergLightTheme.colors.primary,
  },
});

export default ContainerListItem;

import React from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useQuery } from "@tanstack/react-query";
import getAppData from "../queryFunctions/appData";
import SectionTitle from "../components/shared/SectionTitle";
import { List, Text } from "react-native-paper";
import ContainerListItem from "../components/Home/ContainerListItem";
import isFetchingError from "../utilities/isFetchingError";

const Home = () => {
  const {
    data: appData,
    error: appDataError,
    isLoading: isLoadingAppData,
  } = useQuery({
    queryKey: ["appData"],
    queryFn: getAppData,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <ScrollView contentContainerStyle={styles.scrollview}>
        {isLoadingAppData ? (
          <>
            <SectionTitle title="Dine Containere" size={"small"} />
            <View style={styles.spinnerWrapper}>
              <ActivityIndicator size="large" />
            </View>
          </>
        ) : appData ? (
          <>
            <SectionTitle title="Dine Containere" size={"small"} />
            {appData.Units.map((location) => (
              <List.Section key={location.Id}>
                <List.Subheader style={styles.listHeader}>
                  {location.Name ?? "Ukjent navn"} :{" "}
                  {location.Owner ?? "Ukjent eier"}
                </List.Subheader>
                {location.Containers.map((container) => (
                  <ContainerListItem
                    key={container.Id}
                    container={container}
                    address={location.Address}
                    locationName={location.Name}
                    owner={location.Owner}
                  />
                ))}
              </List.Section>
            ))}
          </>
        ) : isFetchingError(appDataError) &&
          appDataError.response?.status === 404 ? (
          <View>
            <SectionTitle
              title={`Du har ikke tilgang til noen containere.`}
              size={"small"}
            />
            <Text variant="bodyLarge" style={styles.descriptionText}>
              Ta kontakt med en administrator for å få tilgang til containere
              med denne brikken
            </Text>
            <View style={styles.imageContainer}>
              <Image
                style={styles.logo}
                source={require("../img/logo-hires.png")}
              />
            </View>
          </View>
        ) : (
          <View>
            <SectionTitle
              title={`Noe har gått galt. Kunne ikke hente data om containere.`}
              size={"small"}
            />
            <Text variant="bodyLarge" style={styles.descriptionText}>
              Gjenopprett nettverksforbindelsen eller start applikasjonen på
              nytt
            </Text>
            <View style={styles.imageContainer}>
              <Image
                style={styles.logo}
                source={require("../img/logo-hires.png")}
              />
            </View>
          </View>
        )}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  scrollview: {
    flex: 1,
    margin: 16,
  },
  descriptionText: {
    textAlign: "center",
    marginVertical: 8,
  },
  listHeader: {
    paddingLeft: 0,
    paddingVertical: 2,
  },
  imageContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    marginVertical: 16,
    width: 90,
    height: 90,
    opacity: 0.4,
  },
  spinnerWrapper: {
    justifyContent: "center",
    height: "100%",
  },
});
export default Home;

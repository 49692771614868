import { z } from "zod";
const AppUser = z.object({
  InternalTagId: z.string(),
});

const NewAppUser = z.object({
  TagId: z.string(),
  PostalCode: z.number(),
  StreetName: z.string(),
  Email: z.string().optional(),
  StreetNumber: z.number().nullable(),
  Letter: z.string().nullable(),
  Apartment: z.string().nullable(),
});

export { AppUser, NewAppUser };
export type AppUserType = z.infer<typeof AppUser>;
export type NewAppUserType = z.infer<typeof NewAppUser>;

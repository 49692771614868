import { AppData } from "../validators/appData";
import { axiosInstance } from "./axiosSetup";

export const getAppData = async () => {
  const tagId = localStorage.getItem("tagId");
  const appDataResponse = await axiosInstance.get(`app_data/${tagId}`, {
    headers: { Authorization: "userToken" },
  });
  return AppData.parse(appDataResponse.data);
};

export const openContainer = async (containerId: string) => {
  const tagId = localStorage.getItem("tagId");
  const appDataResponse = await axiosInstance.put(
    `app_data/${containerId}`,
    null,
    {
      headers: { Authorization: "userToken" },
      params: { tagId: tagId },
    }
  );
  return appDataResponse.data;
};

export default getAppData;

import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Profile from "./Profile.js";
import Icon from "react-native-vector-icons/FontAwesome";
import Home from "./HomeScreen";
import { StrombergLightTheme } from "../styles/strombergTheme";

const Main = () => {
  const Tab = createBottomTabNavigator();

  return (
    <Tab.Navigator
      initialRouteName="Hjem"
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          backgroundColor: StrombergLightTheme.colors.primary,
          paddingBottom: 5,
          paddingTop: 2,
        },
        tabBarActiveTintColor: StrombergLightTheme.colors.inversePrimary,
        tabBarInactiveTintColor: `white`,
      }}
    >
      <Tab.Screen
        options={{
          tabBarIcon: ({ color }) => (
            <Icon name="home" size={28} color={color} />
          ),
        }}
        name="Hjem"
        component={Home}
      />
      <Tab.Screen
        options={{
          tabBarIcon: ({ color }) => (
            <Icon name="user" size={28} color={color} />
          ),
        }}
        name="Profil"
        component={Profile}
      />
    </Tab.Navigator>
  );
};

export default Main;

import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
const useAuthUser = () => {
  const [loggedUser, setLoggedUser] = useState();
  const [idToken, setIdToken] = useState();
  useEffect(async () => {
    try {
      const token = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      setLoggedUser(user);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return { loggedUser };
};

export default useAuthUser;

import { I18n } from "aws-amplify";

const authTranslations = () =>
  I18n.putVocabularies({
    no: {
      "Sign In": "Logg inn",
      "Sign Up": "Opprett bruker",
      "Create Account": "Opprett bruker",
      "Forgot Password?": "Glemt passord?",
      Email: "Email",
      "Enter your Email": "Din epost",
      Password: "Passord",
      "Enter your Password": "Ditt passord",
      "Sign in": "Logg inn",
      "Confirm Password": "Bekreft passord",
      "Please confirm your Password": "Bekreft ditt passord",
      Name: "Navn",
      "Enter your Name": "Ditt navn",
      "This field is required": "Dette feltet er påkrevd",
      "Please enter a valid email": "Venligst skriv inn en gyldig epost",
      "User does not exist.": "Brukeren eksisterer ikke",
      "Creating Account": "Oppretter bruker",
      "We Emailed You": "Epost er sendt",
      "Your code is on the way. To log in, enter the code we emailed to":
        "Din kode er på vei. For å logge inn, skriv inn koden vi sendte til",
      "Show password": "Vis passord",
      Loading: "Laster",
      Code: "Kode",
      "Code *": "Kode *",
      "New Password": "Nytt passord",
      Submit: "Send inn",
      "Resend Code": "Send kode på nytt",
      "Back to Sign In": "Tilbake til innlogging",
      "Send code": "Send kode",
      "Reset Password": "Tilbakestill passord",
      "Enter your email": "Skriv inn din epost",
      "Confirmation Code": "Bekreftelseskode",
      "Enter your code": "Skriv inn din kode",
      Confirm: "Bekreft",
      "It may take a minute to arrive":
        "Det kan ta en liten stund før den ankommer",
      Confirming: "Bekrefter",
    },
  });

export default authTranslations;

import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

const SectionTitle = ({ title, size }) => {
  return (
    <View
      style={
        size === "small"
          ? styles.sectionHeadingContainerSmall
          : styles.sectionHeadingContainerLarge
      }
    >
      <Text variant="headlineSmall" style={styles.sectionHeadingText}>
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeadingContainerLarge: {
    alignItems: "center",
    marginTop: 22,
    marginBottom: 10,
  },
  sectionHeadingContainerSmall: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 5,
  },
  sectionHeadingText: {
    textAlign: "center",
    fontWeight: "400",
  },
});

export default SectionTitle;

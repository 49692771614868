import { Theme } from "@aws-amplify/ui-react-native";

const authTheme: Theme = {
  tokens: {},
  components: {
    textField: {
      fieldContainer: {
        width: "100%",
      },
    },
  },
};

export default authTheme;

import { Auth } from "aws-amplify";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/",
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

import {
  MD3DarkTheme,
  MD3LightTheme,
  adaptNavigationTheme,
} from "react-native-paper";
import {
  NavigationContainer,
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";

const { LightTheme, DarkTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  reactNavigationDark: NavigationDarkTheme,
});

export const StrombergLightTheme = {
  ...MD3LightTheme,
  ...LightTheme,
  colors: {
    ...MD3LightTheme.colors,
    ...LightTheme.colors,
    primary: "rgb(0, 109, 64)",
    onPrimary: "rgb(255, 255, 255)",
    primaryContainer: "rgb(148, 247, 186)",
    onPrimaryContainer: "rgb(0, 33, 16)",
    secondary: "rgb(66, 105, 0)",
    onSecondary: "rgb(255, 255, 255)",
    secondaryContainer: "rgb(175, 247, 71)",
    onSecondaryContainer: "rgb(17, 32, 0)",
    tertiary: "rgb(84, 101, 0)",
    onTertiary: "rgb(255, 255, 255)",
    tertiaryContainer: "rgb(211, 239, 101)",
    onTertiaryContainer: "rgb(24, 30, 0)",
    error: "rgb(186, 26, 26)",
    onError: "rgb(255, 255, 255)",
    errorContainer: "rgb(255, 218, 214)",
    onErrorContainer: "rgb(65, 0, 2)",
    background: "rgb(251, 253, 248)",
    onBackground: "rgb(25, 28, 26)",
    surface: "rgb(251, 253, 248)",
    onSurface: "rgb(25, 28, 26)",
    surfaceVariant: "rgb(220, 229, 219)",
    onSurfaceVariant: "rgb(65, 73, 66)",
    outline: "rgb(113, 121, 114)",
    outlineVariant: "rgb(192, 201, 192)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(46, 49, 46)",
    inverseOnSurface: "rgb(240, 241, 236)",
    inversePrimary: "rgb(120, 218, 159)",
    elevation: {
      level0: "transparent",
      level1: "rgb(238, 246, 239)",
      level2: "rgb(231, 242, 233)",
      level3: "rgb(223, 237, 228)",
      level4: "rgb(221, 236, 226)",
      level5: "rgb(216, 233, 222)",
    },
    surfaceDisabled: "rgba(25, 28, 26, 0.12)",
    onSurfaceDisabled: "rgba(25, 28, 26, 0.38)",
    backdrop: "rgba(42, 50, 44, 0.4)",
    // card: "#006c40",
    light: "#ffffff",
  },
};
export const StrombergDarkTheme = {
  ...MD3DarkTheme,
  ...DarkTheme,
  colors: {
    ...MD3DarkTheme.colors,
    ...DarkTheme.colors,
    primary: "rgb(120, 218, 159)",
    onPrimary: "rgb(0, 57, 31)",
    primaryContainer: "rgb(0, 82, 47)",
    onPrimaryContainer: "rgb(148, 247, 186)",
    secondary: "rgb(148, 218, 41)",
    onSecondary: "rgb(32, 54, 0)",
    secondaryContainer: "rgb(49, 79, 0)",
    onSecondaryContainer: "rgb(175, 247, 71)",
    tertiary: "rgb(183, 210, 76)",
    onTertiary: "rgb(43, 52, 0)",
    tertiaryContainer: "rgb(63, 76, 0)",
    onTertiaryContainer: "rgb(211, 239, 101)",
    error: "rgb(255, 180, 171)",
    onError: "rgb(105, 0, 5)",
    errorContainer: "rgb(147, 0, 10)",
    onErrorContainer: "rgb(255, 180, 171)",
    background: "rgb(25, 28, 26)",
    onBackground: "rgb(225, 227, 222)",
    surface: "rgb(25, 28, 26)",
    onSurface: "rgb(225, 227, 222)",
    surfaceVariant: "rgb(65, 73, 66)",
    onSurfaceVariant: "rgb(192, 201, 192)",
    outline: "rgb(138, 147, 139)",
    outlineVariant: "rgb(65, 73, 66)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(225, 227, 222)",
    inverseOnSurface: "rgb(46, 49, 46)",
    inversePrimary: "rgb(0, 109, 64)",
    elevation: {
      level0: "transparent",
      level1: "rgb(30, 38, 33)",
      level2: "rgb(33, 43, 37)",
      level3: "rgb(36, 49, 41)",
      level4: "rgb(36, 51, 42)",
      level5: "rgb(38, 55, 45)",
    },
    surfaceDisabled: "rgba(225, 227, 222, 0.12)",
    onSurfaceDisabled: "rgba(225, 227, 222, 0.38)",
    backdrop: "rgba(42, 50, 44, 0.4)",
  },
};

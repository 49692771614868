import React, { useEffect, useState } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { ContainerType } from "../validators/appData";
import { Button, Card, Portal, Snackbar, Text } from "react-native-paper";
import SectionTitle from "../components/shared/SectionTitle";
import { StrombergLightTheme } from "../styles/strombergTheme";
import { useMutation } from "@tanstack/react-query";
import { openContainer } from "../queryFunctions/appData";

interface ContainerScreenProps {
  route: {
    params: {
      container: ContainerType;
      locationName: string | null;
      address: string | null;
      owner: string | null;
    };
  };
}

const ContainerScreen = ({ route }: ContainerScreenProps) => {
  const { container, address, locationName, owner } = route.params;
  const [isOpen, setIsOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const {
    mutate: openContainerMutation,
    isLoading: isOpenContainerLoading,
    isSuccess: isOpenContainerSuccess,
    isError: isOpenContainerError,
  } = useMutation({
    mutationFn: openContainer,
  });

  useEffect(() => {
    if (isOpenContainerSuccess) {
      setIsOpen(true);
      setShowSnackbar(true);
    }

    setTimeout(function () {
      setIsOpen(false);
      setShowSnackbar(false);
    }, 7000);
  }, [isOpenContainerSuccess]);

  useEffect(() => {
    if (isOpenContainerError) {
      setShowErrorSnackbar(true);
    }

    setTimeout(function () {
      setShowErrorSnackbar(false);
    }, 7000);
  }, [isOpenContainerError]);

  return (
    <ScrollView contentContainerStyle={styles.scrollview}>
      <Card
        mode="elevated"
        elevation={4}
        style={styles.card}
        contentStyle={styles.cardContent}
      >
        <SectionTitle
          title={`${container.Fraction} - ${owner}`}
          size={"small"}
        />
        <View style={styles.containerDetails}>
          <View style={styles.containerDetailsLine}>
            <View style={styles.leftHalfSizedData}>
              <Text>Lokasjonsnavn:</Text>
            </View>
            <View style={styles.rightHalfSizedData}>
              <Text>{locationName ?? "Ukjent"}</Text>
            </View>
          </View>

          <View style={styles.containerDetailsLine}>
            <View style={styles.leftHalfSizedData}>
              <Text>Adresse:</Text>
            </View>
            <View style={styles.rightHalfSizedData}>
              <Text>{address ?? "Ukjent"}</Text>
            </View>
          </View>

          <View style={styles.containerDetailsLine}>
            <View style={styles.leftHalfSizedData}>
              <Text>Eier:</Text>
            </View>
            <View style={styles.rightHalfSizedData}>
              <Text>{owner ?? "Ukjent"}</Text>
            </View>
          </View>

          <View style={styles.containerDetailsLine}>
            <View style={styles.leftHalfSizedData}>
              <Text>Fraksjon:</Text>
            </View>
            <View style={styles.rightHalfSizedData}>
              <Text>{container.Fraction ?? "Ukjent"}</Text>
            </View>
          </View>

          <View style={styles.containerDetailsLine}>
            <View style={styles.leftHalfSizedData}>
              <Text>Status:</Text>
            </View>
            <View style={styles.rightHalfSizedData}>
              <Text>{container.Active ? "Aktiv" : "Inaktiv" ?? "Ukjent"}</Text>
            </View>
          </View>

          <Button
            style={styles.button}
            icon={
              isOpen
                ? "lock-open-check"
                : isOpenContainerError
                ? "lock-open-remove"
                : "lock-open"
            }
            disabled={!container.Active}
            mode="elevated"
            onPress={() => openContainerMutation(container.Id)}
            loading={isOpenContainerLoading}
          >
            {isOpen ? "Åpen" : container.Active ? "Åpne" : "Inaktiv"}
          </Button>
        </View>
      </Card>

      <View style={styles.imageContainer}>
        <Image style={styles.logo} source={require("../img/logo-hires.png")} />
      </View>
      <Portal>
        <Snackbar
          style={styles.snackSuccess}
          visible={showSnackbar}
          onDismiss={() => setShowSnackbar(false)}
          action={{
            label: "Lukk",
          }}
        >
          Container åpnet
        </Snackbar>
      </Portal>
      <Portal>
        <Snackbar
          visible={showErrorSnackbar}
          onDismiss={() => setShowErrorSnackbar(false)}
          action={{
            label: "Lukk",
          }}
        >
          Container kunne ikke åpnes
        </Snackbar>
      </Portal>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollview: {
    flex: 1,
    margin: 16,
    justifyContent: "center",
  },
  descriptionText: {
    textAlign: "center",
    marginVertical: 8,
  },
  containerDetails: {
    justifyContent: "center",
    marginVertical: 16,
  },
  containerDetailsLine: {
    flexDirection: "row",
    marginVertical: 3,
  },
  card: {
    padding: 10,
  },
  cardContent: {
    justifyContent: "space-between",
  },
  imageContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginVertical: 10,
  },
  leftHalfSizedData: {
    flex: 1,
  },
  rightHalfSizedData: {
    flex: 1,
    marginLeft: 2,
  },
  logo: {
    marginVertical: 16,
    width: 90,
    height: 90,
    opacity: 0.4,
  },
  errorText: {
    color: StrombergLightTheme.colors.error,
  },
  generalError: {
    textAlign: "center",
  },
  snackSuccess: {
    backgroundColor: StrombergLightTheme.colors.primary,
  },
});

export default ContainerScreen;

import React from "react";
import styled from "styled-components/native";
import { colors, layout } from "../styles/variables";
import { Auth } from "aws-amplify";
import useAuthUser from "../utilities/useAuthUser";
const ProfileInfo = () => {
  const logOutUserHandler = () => {
    Auth.signOut();
  };

  const { loggedUser } = useAuthUser();

  return (
    <ProfileInfoContainer>
      <ProfileInfoContent>
        <ProfileInfoHeader>
          <Name>{loggedUser?.attributes?.name}</Name>
          <UserIdWrapper>
            <UserIdTitle>Bruker-ID: </UserIdTitle>
            <UserIdNumber>{loggedUser?.attributes?.sub}</UserIdNumber>
          </UserIdWrapper>
        </ProfileInfoHeader>
        <ProfileInfoMain>
          <Email>
            <EmailTitle>Epost: </EmailTitle>
            <EmailContent>{loggedUser?.attributes?.email}</EmailContent>
          </Email>
          <Address>
            <AdressTitle>Adresse:</AdressTitle>
            <AddressContent>{loggedUser?.attributes?.address}</AddressContent>
          </Address>
        </ProfileInfoMain>
        <LogOutWrapper>
          <LogOut onPress={logOutUserHandler}>
            <LogOutText>Logg ut</LogOutText>
          </LogOut>
        </LogOutWrapper>
      </ProfileInfoContent>
    </ProfileInfoContainer>
  );
};

const ProfileInfoContainer = styled.View`
  height: auto;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 10px;
  border: 1px solid ${colors.lightGrey};
  margin-top: 10px;
`;
const ProfileInfoContent = styled.View`
  ${layout.center};
`;

const ProfileInfoHeader = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.lightGrey};
  padding: 10px;
  width: 100%;
  ${layout.center}
`;

const Name = styled.Text`
  color: ${colors.mainGrey};
  font-size: 18px;
  font-weight: 600;
`;
const UserIdWrapper = styled.View`
  width: 100%;
  height: auto;
  flex-direction: row;
  ${layout.center};
`;

const UserIdTitle = styled.Text`
  color: ${colors.mainGrey};
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
`;

const UserIdNumber = styled(UserIdTitle)`
  font-weight: 600;
`;
const ProfileInfoMain = styled.View`
  width: 100%;
  padding: 10px;
`;

const Email = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const EmailTitle = styled.Text`
  font-size: 15px;
`;
const EmailContent = styled(UserIdNumber)`
  font-size: 15px;
`;
const Address = styled(Email)``;
const AdressTitle = styled(EmailTitle)``;
const AddressContent = styled(EmailContent)``;

const LogOutWrapper = styled.Pressable``;

const LogOut = styled.Pressable`
  background-color: ${colors.darkGreen};
  padding: 7.5px;
  font-size: 20px;
  font-weight: 600;
  margin: 25px;
  border-radius: 5px;
`;

const LogOutText = styled.Text`
  color: ${colors.white};
`;
export default ProfileInfo;

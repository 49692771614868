import { Auth } from "aws-amplify";
import { axiosInstance } from "./axiosSetup";
import { AppUser, NewAppUserType } from "../validators/appUser";

export const getAppUsers = async () => {
  const email = (await Auth.currentAuthenticatedUser()).attributes.email;
  const appUsersResponse = await axiosInstance.get(`app_users?email=${email}`, {
    headers: { Authorization: "userToken" },
  });
  return AppUser.parse(appUsersResponse.data);
};

export const addAppUser = async (user: NewAppUserType) => {
  const email = (await Auth.currentAuthenticatedUser()).attributes.email;
  const completeUser = { ...user, Email: email };
  const appUserResponse = await axiosInstance.post(`app_data`, completeUser, {
    headers: { Authorization: "userToken" },
  });
  return AppUser.parse(appUserResponse.data);
};

export default getAppUsers;

import React, { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Home from "../screens/Main";
import SignUpScreen from "../screens/SignUpScreen";
import { Auth } from "aws-amplify";
import { StrombergLightTheme } from "../styles/strombergTheme";
import { Appbar, Menu } from "react-native-paper";
import ContainerScreen from "../screens/ContainerScreen";
import { ContainerType } from "../validators/appData";

export type RootStackParamList = {
  Home: undefined;
  ContainerScreen: {
    container: ContainerType;
    address: string | null;
    locationName: string | null;
    owner: string | null;
  };
  SignUpScreen: undefined;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

const MainView = () => {
  const Stack = createNativeStackNavigator<RootStackParamList>();
  const navigation = useNavigation();
  const [loggedUser, setLoggedUser] = useState();
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const getUserToken = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const jwtToken = await currentSession?.getIdToken().getJwtToken();
      localStorage.setItem("jwtToken", jwtToken);
      setLoggedUser(user);
    } catch (err) {}
  };

  useEffect(() => {
    getUserToken;
  }, []);

  return (
    <Stack.Navigator initialRouteName={"SignUpScreen"}>
      <Stack.Screen
        options={{
          header: (props) => (
            <Appbar.Header
              style={{
                backgroundColor: `${StrombergLightTheme.colors.primary}`,
              }}
            >
              <Appbar.Content color="white" title="Hjem" />
              <Menu
                onDismiss={closeMenu}
                visible={visible}
                anchorPosition="bottom"
                anchor={
                  <Appbar.Action
                    color="white"
                    icon="account-circle-outline"
                    onPress={openMenu}
                  />
                }
              >
                <Menu.Item
                  leadingIcon="logout"
                  title="Logg ut"
                  onPress={() => {
                    Auth.signOut();
                  }}
                />
              </Menu>
            </Appbar.Header>
          ),
        }}
        name="Home"
        component={Home}
      />

      <Stack.Screen
        options={{
          header: (props) => (
            <Appbar.Header
              style={{
                backgroundColor: `${StrombergLightTheme.colors.primary}`,
              }}
            >
              <Appbar.Content color="white" title="Registrer brikke" />
              <Menu
                onDismiss={closeMenu}
                visible={visible}
                anchorPosition="bottom"
                anchor={
                  <Appbar.Action
                    color="white"
                    icon="account-circle-outline"
                    onPress={openMenu}
                  />
                }
              >
                <Menu.Item
                  leadingIcon="logout"
                  title="Logg ut"
                  onPress={() => {
                    Auth.signOut();
                  }}
                />
              </Menu>
            </Appbar.Header>
          ),
        }}
        name="SignUpScreen"
        component={SignUpScreen}
      />

      <Stack.Screen
        options={{
          header: (props) => (
            <Appbar.Header
              style={{
                backgroundColor: `${StrombergLightTheme.colors.primary}`,
              }}
            >
              <Appbar.BackAction
                color="white"
                onPress={() => navigation.goBack()}
              />
              <Appbar.Content color="white" title="Container detaljer" />
              <Menu
                onDismiss={closeMenu}
                visible={visible}
                anchorPosition="bottom"
                anchor={
                  <Appbar.Action
                    color="white"
                    icon="account-circle-outline"
                    onPress={openMenu}
                  />
                }
              >
                <Menu.Item
                  leadingIcon="logout"
                  title="Logg ut"
                  onPress={() => {
                    Auth.signOut();
                  }}
                />
              </Menu>
            </Appbar.Header>
          ),
        }}
        name="ContainerScreen"
        component={ContainerScreen}
      />
    </Stack.Navigator>
  );
};

export default MainView;
